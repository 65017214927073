:root {
  --font-family: "Goldplay", sans-serif;
  --font-size: 16px;
  --content-width: 1120px;
  --content-sm-width: 1024px;
  --content-lg-width: 1850px;
  --container-offset: 15px;
  --container-sm-offset: 28px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-sm-width: calc(var(--content-sm-width) + (var(--container-sm-offset) * 2));
  --container-lg-width: calc(var(--content-lg-width) + (var(--container-offset) * 2));
  --transition: all .2s ease-in;
  --header-height: 80px;

  // colors
  --color-white: #ffffff;
  --color-black: #000000;
  --color-primary: #40B2E6;
  --color-secondary: #F6894E;

}
