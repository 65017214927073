/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */
:root {
  --font-family: "Goldplay", sans-serif;
  --font-size: 16px;
  --content-width: 1120px;
  --content-sm-width: 1024px;
  --content-lg-width: 1850px;
  --container-offset: 15px;
  --container-sm-offset: 28px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-sm-width: calc(var(--content-sm-width) + (var(--container-sm-offset) * 2));
  --container-lg-width: calc(var(--content-lg-width) + (var(--container-offset) * 2));
  --transition: all .2s ease-in;
  --header-height: 80px;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-primary: #40B2E6;
  --color-secondary: #F6894E;
}

@font-face {
  font-family: "Goldplay";
  src: url("../fonts/../fonts/Goldplay-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Goldplay";
  src: url("../fonts/../fonts/Goldplay-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.page {
  height: 100%;
  font-family: var(--font-family), sans-serif;
  font-size: var(--font-size);
  color: var(--color-black);
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: var(--font-size);
  position: relative;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1, .h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
}
h2, .h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}
h3, .h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
}

h4, .h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

.site-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--header-height);
  transition: var(--transition);
}

.main {
  flex: 1;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
  width: 100%;
}
@media (min-width: 1601px) {
  .container {
    max-width: 1474px;
  }
}

.container-sm {
  margin: 0 auto;
  padding: 0 var(--container-sm-offset);
  max-width: var(--container-sm-width);
  width: 100%;
}

.container-lg {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-lg-width);
  width: 100%;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.ttu {
  text-transform: uppercase;
}

.social {
  list-style-type: none;
  display: flex;
  align-items: center;
}
.social__item:not(:last-child) {
  margin-right: 8px;
}
.social__item-link {
  background: rgba(64, 178, 230, 0.1);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social__item-icon {
  width: auto;
  height: auto;
}

@media (max-width: 767.98px) {
  .hidden-mobile {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .hidden-tablet {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-desktop {
    display: none !important;
  }
}

.header {
  padding: 20px 0;
  transition: var(--transition);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--color-white);
}
.header--hidden {
  transform: translateY(-100%);
}
@media (max-width: 991.98px) {
  .header {
    padding: 8px 0;
    border-bottom: 1px solid #E7E7E7;
  }
}
.header .header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 991.98px) {
  .header .header__inner {
    justify-content: center;
  }
}
.header .header__burger {
  display: none;
}
@media (max-width: 991.98px) {
  .header .header__burger {
    display: flex;
    position: absolute;
    left: var(--container-offset);
  }
}
@media (max-width: 991.98px) {
  .header .header__logo {
    max-width: 68px;
  }
}
@media (min-width: 992px) {
  .header .header__social {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .header .header__social {
    justify-content: center;
    padding-top: 20px;
    margin-top: auto;
  }
}
@media (min-width: 1201px) {
  .header .header__nav {
    margin-right: auto;
    margin-left: 101px;
  }
}
@media (max-width: 991.98px) {
  .header .header__nav {
    position: fixed;
    top: calc(var(--header-height) - 1px);
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 998;
    border-top: 1px solid #E7E7E7;
    background-color: var(--color-white);
    padding: 60px 20px 20px 20px;
    transition: transform 0.3s, opacity 0.4s;
    overflow: auto;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(100%);
  }
  .header .header__nav--active {
    transform: none;
    opacity: 1;
  }
}
.header .header__menu {
  list-style-type: none;
}
@media (min-width: 992px) {
  .header .header__menu {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
  }
}
@media (max-width: 991.98px) {
  .header .header__menu {
    font-size: 24px;
    line-height: 18px;
  }
}
.header .header__menu-item {
  position: relative;
}
@media (min-width: 992px) {
  .header .header__menu-item:not(:last-child) {
    margin-right: 30px;
  }
}
@media (max-width: 991.98px) {
  .header .header__menu-item:not(:last-child) {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .header .header__menu-item--parent > a {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .header .header__menu-item--parent > a::after {
    content: url("../img/angle-down-black.svg");
    display: block;
    margin-left: 7px;
    transform: translateY(-2px);
    transition: transform 0.1s;
  }
  .header .header__menu-item--parent:hover > a::after {
    transform: rotate(180deg) translateY(-3px);
  }
  .header .header__menu-item--parent:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@media (max-width: 991.98px) {
  .header .header__menu-item--parent > a {
    display: none;
  }
}
.header .header__menu-item--active > a {
  color: #9A728E;
  font-weight: 600;
}
@media (min-width: 992px) {
  .header .header__menu-item--active.header__menu-item--parent > a::after {
    content: url("../img/angle-down-purple.svg");
  }
}
.header .header__actions {
  display: flex;
  align-items: center;
}
.header .header__actions--nav-active .header__user {
  transform: scale(1);
}
.header .header__actions--nav-active .header__btn {
  transform: scale(0);
}
@media (min-width: 992px) {
  .header .header__actions > *:not(:last-child) {
    margin-right: 16px;
  }
}
.header .header__user {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 999;
  transition: var(--transition);
}
@media (max-width: 991.98px) {
  .header .header__user {
    position: absolute;
    right: var(--container-offset);
    transform: scale(0);
  }
}
.header .header__user::after {
  content: url("../img/angle-down-blue.svg");
  display: block;
  margin-left: 3px;
  transform: translateY(-2px);
  transition: transform 0.1s;
}
.header .header__user:hover::after {
  transform: rotate(180deg) translateY(-3px);
}
.header .header__user:hover .user-sub-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.header .header__user-icon {
  background: rgba(64, 178, 230, 0.1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991.98px) {
  .header .header__btn {
    position: absolute;
    right: var(--container-offset);
  }
}
@media (max-width: 767.98px) {
  .header .header__btn {
    padding: 8px 12px;
  }
}
@media (max-width: 991.98px) {
  .header .burger {
    --burger-width: 20px;
    --burger-height: 14px;
    --burger-line-height: 2px;
    position: relative;
    border: none;
    padding: 0;
    width: var(--burger-width);
    height: var(--burger-height);
    color: var(--color-primary);
    background-color: transparent;
    cursor: pointer;
  }
  .header .burger::before, .header .burger::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: var(--burger-line-height);
    background-color: currentColor;
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    border-radius: 5px;
  }
  .header .burger::before {
    top: 0;
  }
  .header .burger::after {
    top: calc(100% - var(--burger-line-height));
  }
  .header .burger__line {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: var(--burger-line-height);
    background-color: currentColor;
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
    border-radius: 5px;
  }
  .header .burger--active::before {
    top: 50%;
    transform: rotate(45deg);
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  }
  .header .burger--active::after {
    top: 50%;
    transform: rotate(-45deg);
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  }
  .header .burger--active .burger__line {
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
  }
}

.sub-menu {
  list-style-type: none;
}
@media (min-width: 992px) {
  .sub-menu {
    padding: 20px;
    background-color: var(--color-white);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-width: 180px;
    transition: var(--transition);
    position: absolute;
    z-index: 999;
    top: calc(100% + 10px);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
  .sub-menu::before {
    content: "";
    display: block;
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 100%;
    left: 0;
  }
}
.sub-menu__item--active > a {
  color: #9A728E;
  font-weight: 600;
}
@media (min-width: 992px) {
  .sub-menu__item:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media (max-width: 991.98px) {
  .sub-menu__item:not(:last-child) {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .sub-menu__link {
    font-size: 16px;
    line-height: 16px;
  }
}

.user-sub-menu {
  list-style-type: none;
  padding: 20px;
  background-color: var(--color-white);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-width: 180px;
  transition: var(--transition);
  position: absolute;
  z-index: 999;
  top: calc(100% + 10px);
  right: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
}
.user-sub-menu::before {
  content: "";
  display: block;
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 100%;
  left: 0;
}
.user-sub-menu__item--active > a {
  color: #9A728E;
  font-weight: 600;
}
.user-sub-menu__item:not(:last-child) {
  margin-bottom: 20px;
}
.user-sub-menu__link {
  font-size: 16px;
  line-height: 16px;
}