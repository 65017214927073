html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.page {
  height: 100%;
  font-family: var(--font-family), sans-serif;
  font-size: var(--font-size);
  color: var(--color-black);
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: var(--font-size);
  position: relative;
}

p {
  margin: 0;
}
h1,h2,h3,h4,h5,h6 {
  margin: 0;
}
h1, .h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  @include max-767 {

  }
}
h2, .h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  @include max-767 {

  }
}
h3, .h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
}
h4, .h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
}
img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--header-height);
  transition: var(--transition)
}
.main {
  flex: 1;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
  width: 100%;
  @include min-1601 {
    max-width: 1474px;
  }
}
.container-sm {
  margin: 0 auto;
  padding: 0 var(--container-sm-offset);
  max-width: var(--container-sm-width);
  width: 100%;
}
.container-lg {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-lg-width);
  width: 100%;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.ttu {
  text-transform: uppercase;
}

.social {
  list-style-type: none;
  display: flex;
  align-items: center;
  &__item {
    &:not(:last-child) {
      margin-right: 8px;
    }
    &-link {
      background: rgba(64, 178, 230, 0.1);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-icon {
      width: auto;
      height: auto;
    }
  }
}

.hidden-mobile {
  @include max-767 {
    display: none!important;
  }
}
.hidden-tablet {
  @include max-991 {
    display: none!important;
  }
}
.hidden-desktop {
  @include min-992 {
    display: none!important;
  }
}
