/* stylelint-disable */
@mixin max-360 {
  @media (max-width: (350.98px)) {
    @content;
  }
}

@mixin max-560 {
  @media (max-width: (560.98px)) {
    @content;
  }
}

@mixin max-767 {
  @media (max-width: (767.98px)) {
    @content;
  }
}
@mixin max-991 {
  @media (max-width: (991.98px)) {
    @content;
  }
}

@mixin max-1024 {
  @media (max-width: (1024.98px)) {
    @content;
  }
}

@mixin max-1200 {
  @media (max-width: (1200.98px)) {
    @content;
  }
}

@mixin max-1440 {
  @media (max-width: (1440.98px)) {
    @content;
  }
}

@mixin max-1600 {
  @media (max-width: (1600.98px)) {
    @content;
  }
}

@mixin min-1601 {
  @media (min-width: (1601px)) {
    @content;
  }
}

@mixin min-1440 {
  @media (min-width: (1441px)) {
    @content;
  }
}

@mixin min-1201 {
  @media (min-width: (1201px)) {
    @content;
  }
}

@mixin min-1025 {
  @media (min-width: (1025px)) {
    @content;
  }
}

@mixin min-992 {
  @media (min-width: (992px)) {
    @content;
  }
}

@mixin min-768 {
  @media (min-width: (768px)) {
    @content;
  }
}
@mixin min-561 {
  @media (min-width: (561px)) {
    @content;
  }
}

