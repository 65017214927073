.header {
  padding: 20px 0;
  transition: var(--transition);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--color-white);
  &--fixed {

  }
  &--hidden {
    transform: translateY(-100%);
  }

  @include max-991 {
    padding: 8px 0;
    border-bottom: 1px solid #E7E7E7;
  }

  .header {
    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      @include max-991 {
        justify-content: center;
      }
    }

    &__burger {
      display: none;

      @include max-991 {
        display: flex;
        position: absolute;
        left: var(--container-offset);
      }
    }

    &__logo {
      @include max-991 {
        max-width: 68px;
      }

      &-img {}
    }

    &__social {
      @include min-992 {
        display: none;
      }

      @include max-991 {
        justify-content: center;
        padding-top: 20px;
        margin-top: auto;
      }
    }

    &__nav {
      @include min-1201 {
        margin-right: auto;
        margin-left: 101px;
      }
      @include max-991 {
        position: fixed;
        top: calc(var(--header-height) - 1px);
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 998;
        border-top: 1px solid #E7E7E7;
        background-color: var(--color-white);
        padding: 60px 20px 20px 20px;
        transition: transform .3s, opacity .4s;
        overflow: auto;
        display: flex;
        flex-direction: column;

        opacity: 0;
        transform: translateY(100%);

        &--active {
          transform: none;
          opacity: 1;
        }
      }
    }

    &__menu {
      list-style-type: none;

      @include min-992 {
        display: flex;
        align-items: center;

        font-size: 18px;
        line-height: 18px;
      }

      @include max-991 {
        font-size: 24px;
        line-height: 18px;
      }

      &-item {
        position: relative;

        @include min-992 {
          &:not(:last-child) {
            margin-right: 30px;
          }
        }

        @include max-991 {
          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
        &--parent {
          @include min-992 {
            &>a {
              position: relative;
              display: flex;
              align-items: center;
              cursor: pointer;

              &::after {
                content: url('../img/angle-down-black.svg');
                display: block;
                margin-left: 7px;
                transform: translateY(-2px);
                transition: transform .1s;
              }
            }

            &:hover {
              &>a::after {
                transform: rotate(180deg) translateY(-3px);
              }

              .sub-menu {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
              }
            }
          }

          @include max-991 {
            &>a {
              display: none;
            }
          }

        }
        &--active {
          & > a {
            color: #9A728E;
            font-weight: 600;
          }
          &.header__menu-item--parent {
            & > a {
              @include min-992 {
                &::after {
                  content: url('../img/angle-down-purple.svg');
                }
              }
            }
          }
        }
      }

      &-link {}
    }

    &__actions {
      display: flex;
      align-items: center;

      &--nav-active {
        .header__user {
          transform: scale(1);
        }
        .header__btn {
          transform: scale(0);
        }
      }
      @include min-992 {
        & > *:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    &__user {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      z-index: 999;
      transition: var(--transition);
      @include max-991 {
        position: absolute;
        right: var(--container-offset);
        transform: scale(0);
      }

      &::after {
        content: url('../img/angle-down-blue.svg');
        display: block;
        margin-left: 3px;
        transform: translateY(-2px);
        transition: transform .1s;
      }

      &:hover {
        &::after {
          transform: rotate(180deg) translateY(-3px);
        }
        .user-sub-menu {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }

      &-icon {
        background: rgba(64, 178, 230, 0.1);
        border-radius: 50%;
        width: 40px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

      }
    }

    &__btn {
      @include max-991 {
        position: absolute;
        right: var(--container-offset);
      }

      @include max-767 {
        padding: 8px 12px;
      }
    }
  }

  .burger {
    @include max-991 {
      @include burger
    }
  }
}

.sub-menu {
  list-style-type: none;

  @include min-992 {
    padding: 20px;
    background-color: var(--color-white);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-width: 180px;
    transition: var(--transition);

    position: absolute;
    z-index: 999;
    top: calc(100% + 10px);

    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 10px;

      position: absolute;
      bottom: 100%;
      left: 0;
    }
  }

  &__item {
    &--active {
      & > a {
        color: #9A728E;
        font-weight: 600;
      }
    }

    &:not(:last-child) {
      @include min-992 {
        margin-bottom: 20px;
      }

      @include max-991 {
        margin-bottom: 40px;
      }
    }
  }

  &__link {
    @include min-992 {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.user-sub-menu {
  list-style-type: none;
  padding: 20px;
  background-color: var(--color-white);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-width: 180px;
  transition: var(--transition);

  position: absolute;
  z-index: 999;
  top: calc(100% + 10px);
  right: 0;

  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 10px;

    position: absolute;
    bottom: 100%;
    left: 0;
  }

  &__item {
    &--active {
      & > a {
        color: #9A728E;
        font-weight: 600;
      }
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 16px;
  }
}
